import { Component } from "react/cjs/react.production.min";
import { withRouter } from "react-router-dom";

import Game from "./Game";

class Results extends Component {
  state = {
    loading: true,
    status: -1,
    games: [],
    user: "",
  };

  async componentDidMount() {
    this.state.user = this.props.match.params.user;
    const status = this.props.match.params.status;
    const minBggRating = this.props.match.params.minbggrating;

    const ownParam = status === "own" ? `&own=1` : ``;
    const prevOwnParam = status === "prevOwned" ? `&prevowned=1` : ``;
    const wishlistParam = status === "wishlist" ? `&wishlist=1` : ``;
    const minBggRatingParam = minBggRating
      ? `&minbggrating=${+minBggRating}`
      : ``;

    let res = await fetch(
      `https://api.geekdo.com/xmlapi2/collection?username=${this.state.user}&stats=1` +
        ownParam +
        prevOwnParam +
        wishlistParam +
        minBggRatingParam
    );
    if (res.status == 202) {
      this.setState(
        Object.assign({
          loading: false,
          status: res.status,
        })
      );
    } else {
      const xml = await res.text();
      console.log(xml);
      let parent = this;

      let parseString = require("xml2js").parseString;
      parseString(
        xml,
        { attrkey: "data", charkey: "text" },
        function (err, result) {
          console.log(result);
          if (result.items.data.totalitems != 0) {
            parent.setState(
              Object.assign({
                loading: false,
                games: result.items.item,
              })
            );
          }
        }
      );
    }
  }

  render() {
    return (
      <div className="search-result">
        <div className="search-result-wrap">
          {this.state.status == 202 ? (
            <h2>
              Your request has been submitted to BGG. Please refresh the page
            </h2>
          ) : !this.state.games.length ? (
            <h2>No Games Found</h2>
          ) : (
            this.state.games.map((game) => (
              <Game
                user={this.state.user}
                title={game.name[0].text}
                img={game.image[0]}
                year={game["yearpublished"] ? game.yearpublished[0] : ""}
                id={game.data.objectid}
                key={game.data.objectid}
                minplayers={game.stats[0].data.minplayers}
                maxplayers={game.stats[0].data.maxplayers}
                minplaytime={game.stats[0].data.minplaytime}
                maxplaytime={game.stats[0].data.maxplaytime}
                rating={game.stats[0].rating[0].average[0].data.value}
                usersrated={game.stats[0].rating[0].usersrated[0].data.value}
              />
            ))
          )}
        </div>
      </div>
    );
  }
}

const ResultsRouter = withRouter(Results);

export default function DetailsWithErrorBoundary() {
  return <ResultsRouter />;
}

// const Results = ({ user, games }) => {
//   return (
//     <div className="search-result">
//       {!games.length ? (
//         <h2>No Games Found</h2>
//       ) : (
//         games.map((game) => (
//           <Game
//             user={user}
//             title={game.name[0].text}
//             img={game.image[0]}
//             year={game.yearpublished[0]}
//             id={game.data.objectid}
//             key={game.data.objectid}
//             minplayers={game.stats[0].data.minplayers}
//             maxplayers={game.stats[0].data.maxplayers}
//             minplaytime={game.stats[0].data.minplaytime}
//             maxplaytime={game.stats[0].data.maxplaytime}
//             rating={game.stats[0].rating[0].average[0].data.value}
//             usersrated={game.stats[0].rating[0].usersrated[0].data.value}
//           />
//         ))
//       )}
//     </div>
//   );
// };

//export default Results;
