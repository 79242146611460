import ReactDOM from "react-dom";
import { BrowserRouter as Router, Route, Switch, Link } from "react-router-dom";
import SearchParams from "./Components/SearchParams";
import Details from "./Components/Details";
import Results from "./Components/Results";

const App = () => {
  return (
    <div>
      <Router>
        <header>
          <Link to="/">
            <img src={`${require("../media/logo.png")}`} alt="My BGG"></img>
          </Link>
        </header>
        <Switch>
          <Route path="/game/:id/:user?">
            <Details />
          </Route>
          <Route path="/results/:user/:minbggrating/:status?">
            <Results />
          </Route>
          <Route path="/">
            <SearchParams />
          </Route>
        </Switch>
      </Router>
    </div>
  );
};

ReactDOM.render(<App />, document.getElementById("root"));
/* 
const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(<App />);*/
