import { Link } from "react-router-dom";

const Game = ({
  user,
  title,
  img,
  year,
  id,
  minplayers,
  maxplayers,
  minplaytime,
  maxplaytime,
  rating,
  usersrated,
}) => {
  console.log(title);
  let playersInfo = "";
  let durationInfo = "";
  let ratingInfo = "";
  if (maxplayers) {
    playersInfo = (
      <div>
        <h3 className="number-players">
          <span>Players: </span>
          {minplayers} {minplayers != maxplayers ? `- ${maxplayers}` : ""}{" "}
        </h3>
      </div>
    );
  }
  if (maxplaytime) {
    durationInfo = (
      <div>
        <h3 className="duration">
          <span>Duration: </span>
          {minplaytime}{" "}
          {minplaytime != maxplaytime && maxplaytime ? `- ${maxplaytime}` : ""}{" "}
          Min
        </h3>
      </div>
    );
  }
  if (rating) {
    ratingInfo = (
      <div
        className={`${
          Number(usersrated) < 30
            ? "grey-game"
            : Number(rating).toFixed(1) < 5
            ? "red-game"
            : Number(rating).toFixed(1) < 8
            ? "blue-game"
            : "green-game"
        } rating`}
      >
        <b>{Number(usersrated) < 30 ? "--" : Number(rating).toFixed(1)}</b>
      </div>
    );
  }
  return (
    <div className="game">
      <div className="game-header">
        <Link to={`/game/${user}/${id}`}>
          <div className="image-wrap">
            <img src={img} className="image-game" alt="game cover" />
          </div>
        </Link>
        {ratingInfo}
        <Link to={`/game/${id}/${user}`}>
          <div className="game-data">
            <h1 className="title-game">{title}</h1>
            <h2 className="year-game">{year}</h2>
          </div>
        </Link>
      </div>
      <div className="game-info">
        {playersInfo}
        {durationInfo}
      </div>
    </div>
  );
};

export default Game;
